<mat-card class="song-card">
    <mat-card-header>
      <mat-card-title style="text-align: center;">{{song?.title}}</mat-card-title>
    </mat-card-header>
    <img  mat-card-md-image *ngIf="song?.cover; else placeHolderBlock" [src]="getImageUrl()" alt="Cover of {{song?.title}}" [width]="song?.cover?.width"
    [height]="song?.cover?.height">
    <ng-template #placeHolderBlock>
      <cover-placeholder mat-card-md-image></cover-placeholder>
    </ng-template>
    <mat-card-content>
      <p *ngIf="song?.lyrics">
        {{ getLyricsPreview() }}
      </p>
    </mat-card-content>
    <mat-card-actions align="end">
      <a mat-button *ngIf="song?.soundcloud" target="_blank" [href]="song?.soundcloud?.url"><mat-icon fontSet="fa-brands" fontIcon="fa-soundcloud" ></mat-icon><span class="text">Sondcloud</span></a>
      <a mat-button *ngIf="song?.youtube"  target="_blank"  [href]="song?.youtube?.url"><mat-icon fontSet="fa-brands" fontIcon="fa-youtube"></mat-icon> <span class="text">YouTube</span></a>
      <button mat-raised-button color="primary" [routerLink]="[song?.id]">Lyrics anzeigen</button>
    </mat-card-actions>
  </mat-card>
  