<article [ngClass]="{'without-img': !(song?.cover)}">
    <h2>{{song?.title}}</h2>
    <div class="right-side-cotainer">
        <app-soundcloud-iframe *ngIf="song?.soundcloud?.iframe && !isMobile" [song]="song"></app-soundcloud-iframe>
        <img *ngIf="song?.cover; else placeHolderBlock" [src]="getImageUrl()" class="song-cover" alt="Song-Cover für {{song?.title}}" />
    </div>
 
    <ng-template #placeHolderBlock>
        <cover-placeholder></cover-placeholder>
    </ng-template>
   
    <section class="lyrics" [innerHTML]="song?.lyrics"></section>
    <section class="action-footer">
        <a mat-button *ngIf="song?.soundcloud"  mat-button target="_blank" [href]="song?.soundcloud?.url"><mat-icon fontSet="fa-brands" fontIcon="fa-soundcloud" ></mat-icon> Soundcloud</a>
        <a mat-button *ngIf="song?.youtube"  target="_blank"  mat-button [href]="song?.youtube?.url"><mat-icon fontSet="fa-brands" fontIcon="fa-youtube" ></mat-icon> YouTube</a>
    </section>
</article>  
<app-overlay-song-list></app-overlay-song-list>