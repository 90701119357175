import { ImplicitReceiver } from '@angular/compiler';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Song } from '../shared/song';
import { SongService } from '../shared/song.service';

@Component({
  selector: 'app-overlay-song-list',
  templateUrl: './overlay-song-list.component.html',
  styleUrls: ['./overlay-song-list.component.scss'],
})
export class OverlaySongListComponent implements OnInit {
  songs: Song[] = [];
  @Input() song: Song | undefined;
  @Output() showDetailsEvent = new EventEmitter<Song>();
  isVisibleOnMobile: boolean = false;

  constructor(private songService: SongService, private el: ElementRef) {}
  ngOnInit(): void {
    this.songService.getAll().subscribe((res) => (this.songs = res));
  }
  onMobileToggleClick(): void {
    this.isVisibleOnMobile = true;
    document.addEventListener('click', this.onDocumentClick);
  }

  onMobileClose(): void {
    this.isVisibleOnMobile = false;
    document.removeEventListener('click', this.onDocumentClick);
  }

  // Event listener for document clicks
  onDocumentClick = (event: MouseEvent) => {
    const componentElement = this.el.nativeElement;
    const isClickedInsideComponent = componentElement.contains(
      event.target as Node
    );

    if (!isClickedInsideComponent) {
      this.onMobileClose();
    }
  };

  ngOnDestroy(): void {
    // Remove the document click event listener
    document.removeEventListener('click', this.onDocumentClick);
  }
}
