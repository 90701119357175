import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Song } from '../shared/song';
import { SongService } from '../shared/song.service';

@Component({
  selector: 'app-song-grid',
  templateUrl: './song-grid.component.html',
  styleUrls: ['./song-grid.component.scss'],
})
export class SongListComponent implements OnInit {
  songs: Song[] = [];
  @Output() showDetailsEvent = new EventEmitter<Song>();

  constructor(private songService: SongService) {}
  ngOnInit(): void {
    this.songService.getAll().subscribe((res) => (this.songs = res));
  }
  showDetails(song: Song) {
    this, this.showDetailsEvent.emit(song);
  }
}
