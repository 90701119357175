<div class="overlay-song-list" [ngClass]="{'active': this.isVisibleOnMobile}">
    <a href="#" class="mobile-toogle" (mousedown)="this.onMobileToggleClick()">
        <mat-icon aria-hidden="false" aria-label="mobile menu toggle" fontIcon="menu"></mat-icon>
    </a>
    
    <ul >
        <li *ngFor="let s of songs"><a [routerLink]="['../'+s.id]" routerLinkActive="active">{{s.title}}</a></li>
    </ul>
</div>


