import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { SongListComponent } from './song-grid/song-grid.component';
import { SongListItemComponent } from './song-grid-item/song-grid-item.component';
import { SongDetailsComponent } from './song-details/song-details.component';
import { DataProtectionComponent } from './data-protection/data-protection.component';
import { OverlaySongListComponent } from './overlay-song-list/overlay-song-list.component';
import { CoverPlaceholderComponent } from './cover-placeholder/cover-placeholder.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SpinnerComponent } from './spinner/spinner.component';
import { LoadingInterceptor } from './shared/loading.interceptor';
import { SoundcloudIframeComponent } from './soundcloud-iframe/soundcloud-iframe.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SongListComponent,
    SongListItemComponent,
    SongDetailsComponent,
    DataProtectionComponent,
    OverlaySongListComponent,
    CoverPlaceholderComponent,
    SpinnerComponent,
    SoundcloudIframeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatGridListModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
  ],
  providers: [
    MatIconRegistry,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    matIconRegistry.registerFontClassAlias('fontawesome brands', 'fa-brands');
    provideHttpClient(withFetch());
  }
}
