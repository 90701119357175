import { Component, Input, OnInit } from '@angular/core';
import { Song } from '../shared/song';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import * as htmlToText from 'html-to-text';
@Component({
  selector: 'a.larp-lilie-song-grid-item',
  templateUrl: './song-grid-item.component.html',
  styleUrls: ['./song-grid-item.component.scss'],
})
export class SongListItemComponent implements OnInit {
  @Input() song: Song | undefined;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}
  getImageUrl(): string | null {
    if (this.song?.cover)
      return `${environment.imageBaseUrl}${this.song?.cover?.url}`;

    return null;
  }
  getLyricsPreview(): string | null {
    if (this.song?.lyrics) {
      let content = htmlToText.htmlToText(this.song.lyrics, {});
      content = content.replaceAll(/\[([A-z]|[0-9]|\.|\-|\s)*\]/g, '');
      if (content.length > 200) content = content.substring(0, 200) + ' ...';
      return content;
    }
    return null;
  }
}
