import { ObjectUnsubscribedError } from 'rxjs';

export class Song {
  constructor(
    public id: number,
    public title: string,
    public locale: string,
    public createdAt: string,
    public publishedAt: string,
    public lyrics?: string,
    public updatedAt?: string,
    public youtube?: ExternalSource | null,
    public soundcloud?: ExternalSource | null,
    public cover?: SongCover | null
  ) {}
}

export class SongCover {
  constructor(
    public url: string,
    public width: number,
    public height: number
  ) {}
}

export class ExternalSource {
  constructor(public url: string, public iframe?: string | null) {}
}
