<nav>
<div class="blur-background"></div>
  <mat-toolbar color="primary">
    <a mat-button routerLink="home"  routerLinkActive="active" class="item">Home</a>
    <a mat-button routerLink="songs" routerLinkActive="active" class="item">Songs</a>
  </mat-toolbar>
</nav>
<main>
  <router-outlet></router-outlet> 
</main>
<app-spinner></app-spinner>
