import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Song } from '../shared/song';
import { ActivatedRoute, Router } from '@angular/router';
import { SongService } from '../shared/song.service';
import { environment } from 'src/environments/environment';
import { Meta, Title, DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { klaroConfig } from '../shared/klaro-config';

const MAX_WIDTH_MOBILE = 768;
@Component({
  selector: 'app-song-details',
  templateUrl: './song-details.component.html',
  styleUrls: ['./song-details.component.scss'],
})
export class SongDetailsComponent implements OnInit, OnDestroy {
  private _Song: Song | null = null;

  @Input() public get song(): Song | null {
    return this._Song;
  }
  public set song(v: Song | null) {
    this._Song = v;
  }

  private _ResizeObserver: ResizeObserver;
  public isMobile: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private songservice: SongService,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private zone: NgZone
  ) {
    this._ResizeObserver = new ResizeObserver((entries) => {
      if (this.isMobile && entries[0].contentRect.width > MAX_WIDTH_MOBILE) {
        this.zone.run(() => {
          this.isMobile = false;
        });
      } else if (
        !this.isMobile &&
        entries[0].contentRect.width <= MAX_WIDTH_MOBILE
      ) {
        this.zone.run(() => {
          this.isMobile = true;
        });
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.songservice.getSongById(params['id']).subscribe((s) => {
        this.song = s;
        this.title.setTitle(this.song?.title ?? this.title.getTitle());
        const title = `LarpLilie - ${this.song?.title}`;
        this.meta.updateTag({ property: 'title', content: title });
        this.meta.updateTag({ property: 'og:title', content: title });
        const imageUrl = this.getImageUrl();
        if (imageUrl) {
          this.meta.updateTag({
            property: 'og:image',
            content: imageUrl,
          });
        }
        this.meta.updateTag({
          property: 'description',
          content: `${s?.title} an LarpLilien Original`,
        });
        this.meta.updateTag({
          property: 'og:description',
          content: `${s?.title} an LarpLilien Original`,
        });
      });
      this.meta.updateTag({
        property: 'og:url',
        content: `${environment.hostUrl}${this.router.url}`,
      });
    });
    this._ResizeObserver.observe(document.body);
  }
  ngOnDestroy() {
    this._ResizeObserver.unobserve(document.body);
  }

  getImageUrl(): string | null {
    if (this.song?.cover)
      return `${environment.imageBaseUrl}${this.song?.cover?.url}`;

    return null;
  }
  getIframeUrl(song : Song | null): SafeUrl | null {
    if (song?.soundcloud?.iframe)
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        song?.soundcloud?.iframe
      );
    return null;
  }
}
