<div class="ui container column">
  <h2>Über mich</h2>
 <img src="/assets/larp.jpg" alt="LarpLilie singing and playing the gituar" />
 <article>
    <b>Hallo ihr Lieben!</b>
    <br />
    <p>Ich bin Lilie. Mein Twitch-Name LarpLilie setzt sich aus meinem Hobby “Larp” (Live Action Role Play, also so etwas wie mittelalterlichem Improvisationstheater) und dem Namen meines Charakters, den ich dort spiele, zusammen.<br />
      Lilie ist eine aus dem “Witcher”-Setting stammenden Bardin aus Temerien (woher sie ihren Namen hat, da die Lilie das Wappen der temerischen Banner ziert).</p>
    <p>Ich streame seit Ende März 2021 auf Twitch. Begonnen habe ich, um mich selbst mehr zum Gitarrespielen und Singen zu bewegen, weil ich es zwar gerne tue, es aber zu wenig in meinem Alltag integrieren konnte.<br />
      Auf YouTube landen schließlich Schnitte einzelner Lieder aus meinen Twitch-Streams.</p>
    <p>
      Meine Musik ist eher ruhig. Viele Balladen mit Gitarre und Gesang. Die Lieder reichen von Covern über eigene Songs bis hin zu mittelalterlichen (Larp-)Stücken. <br />
      Meine Twitch-Streaming-Zeiten sind mittwochs und sonntags ab 20 Uhr.
    </p>
    <p>
      In meinen Streams teile ich meine Liebe zur Musik und das damit einhergehende Zusichkommen und Abschalten. Ebenso spielt der Spaß, der durch den Chat entsteht, eine große Rolle in der Community.<br />
      Es geht aber auch um mentale Gesundheit und andere Themen, die eher ernsteren Charakter haben. <br />
      Eben eine gesunde Mischung aus allem.
    </p>
 </article>
</div>
