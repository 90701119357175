import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SongListComponent } from './song-grid/song-grid.component';
import { SongDetailsComponent } from './song-details/song-details.component';
import { DataProtectionComponent } from './data-protection/data-protection.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'songs',
    component: SongListComponent,
    title: 'Song-Übersicht',
  },
  {
    path: 'songs/:id',
    component: SongDetailsComponent,
  },
  {
    path: 'datenschutz',
    component: DataProtectionComponent,
  },
  {
    path: 'Datenschutz',
    redirectTo: 'datenschutz',
  },
  {
    path: 'dataprotection',
    redirectTo: 'datenschutz',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
