import { environment } from 'src/environments/environment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { filter, lastValueFrom } from 'rxjs';

const defaultMetadata = {
  author: 'LarpLilie',
  keywords: ['Songwriter', 'Twitch', 'Larp'],
  type: 'website',
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(private meta: Meta, private router: Router) {}

  ngOnInit() {
    this.meta.updateTag({
      property: 'og:url',
      content: `${environment.hostUrl}`,
    });
    this.meta.updateTag({ name: 'author', content: defaultMetadata.author });
    this.meta.updateTag({
      property: 'og:author',
      content: defaultMetadata.author,
    });
    this.meta.updateTag({
      property: 'keyword',
      content: defaultMetadata.keywords.join(', '),
    });
    this.meta.updateTag({ property: 'og:type', content: defaultMetadata.type });
  }

  ngOnDestroy() {}
}
