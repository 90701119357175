export const environment = {
  production: false,
  // apiKey:
  //   'd016aac6081719fd922902ff36bb97fcf0a240860a349070f155a3b328d31c5da9757c2a0b012b035c4b54cde82891ac3e38eb7b32f41941c0fc1b1eaac46f8f67dd68bb96e59db13ab676a279612867b84ab326a0c10a58ebd233cc0bd63e672c8c27bccb64131f91431ee82ed0030ef21aefa6368ababa2cde5955aec1c176',
  // apiServer: 'http://localhost:1337/api',
  // imageBaseUrl: 'http://localhost:1337',
  apiKey:
    'b966ad83b1cc401e0cdfb50cba18d114eec9298265bd78c3109b6c851a5670933ae18afec82e202c030276cfe524ab8fa2509c843815884349b167c8250142ed27568d41b22730f8a2ddb54fa2f7967c45f8ddf0d7820736317f68ccfdd20d0843afa50a554ae4391ac80eef0af5d27e36e8118d63c15fe58c161c8b3d333d03',
  apiServer: 'https://content.larplilie.de/api',
  imageBaseUrl: 'https://content.larplilie.de',
  hostUrl: 'https://larplilie.de',
};
