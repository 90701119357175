// we define a minimal configuration
export var klaroConfig = {
  testing: true,
  // elementID: 'klaro',
  // storageMethod: 'localStorage',
  // storageName: 'klaro',
  // htmlTexts: false,
  default: false,
  mustConsent: false,
  acceptAll: true,
  hideDeclineAll: false,
  hideLearnMore: true,
  disablePoweredBy: true,

  translations: {
    zz: {
      privacyPolicyUrl: '/datenschutz/',
    },
    de: {
      decline: 'Nur notwendige',
      ok: 'Ok',
      privacyPolicyUrl: '#/datenschutz',
      consentNotice: {
        description:
          'Diese Seite benutzt Services, die unter Umständen Ihre Erlaubnis benötigen. (Nicht nur Mod mögen Cookies 🍪)',
        learnMore: 'Details anzeigen',
      },
      consentModal: {
        description:
          'Hier können Sie einsehen und anpassen, welche Information wir wie verarbeiten.',
        title: 'Dienste, die wir nutzen',
      },
      purposes: {
        general: {
          title: 'Generelle Dienste',
        },
      },
    },
    en: {
      decline: 'Only required',
      ok: 'Ok',
      privacyPolicyUrl: '/datenschutz/',
      consentNotice: {
        description:
          'This Page uses services, that may need your permissions. (not only mods like cookies 🍪)',
        learnMore: 'Show Details',
      },
      consentModal: {
        description:
          'Here you can view and adjust what information we process.',
        title: 'Services, we use',
      },
      purposes: {
        general: {
          title: 'General Services',
        },
      },
    },
  },

  services: [
    {
      name: 'soundcloud',
      default: false,

      translations: {
        zz: {
          title: 'Soundcloud',
        },
        de: {
          description:
            'Wir weisen darauf hin, dass bei der Nutzung von Soundcloud Cookies von Souundcloud eingesetzt werden, sodass Ihre Nutzungsdaten bei der Nutzung von Soundcloud  auf dieser Seite auch an Soundcloud weitergegeben werden können. Wir als Websitebetreiber haben auf diese Verarbeitung keinen Einfluss. Weitere Informationen entnehmen Sie bitte unserer Datenschutzerklärung bzw. unter https://soundcloud.com/pages/cookies',
        },
      },
      purposes: ['media'],

      cookies: ['soundcloud'],
      contextualConsentOnly: true,
      // required: true,
      // optOut: false,
      onlyOnce: true,
    },
  ],

  callback: function (consent: any, service: any) {
    console.log(
      'User consent for service ' + service.name + ': consent=' + consent
    );
  },
};
