import { Song } from './../shared/song';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { klaroConfig } from '../shared/klaro-config';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NonNullAssert } from '@angular/compiler';

@Component({
  selector: 'app-soundcloud-iframe',
  templateUrl: './soundcloud-iframe.component.html',
  styleUrls: ['./soundcloud-iframe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoundcloudIframeComponent implements OnInit, OnChanges {
  @ViewChild('iframe') iframe: ElementRef | undefined;
  public constructor(private sanitizer: DomSanitizer) {}
  ngOnChanges(changes: SimpleChanges): void {}

  private _Song: Song | null = null;
  private _hasConsent: any = false;
  public get hasConsent(): boolean {
    return this._hasConsent === true;
  }
  @Input() public get song(): Song | null {
    return this._Song;
  }
  public set song(v: Song | null) {
    this._Song = v;
  }

  ngOnInit(): void {
    // Load Klaro.js configuration
    const klaroConfigScript = document.createElement('script');
    klaroConfigScript.textContent = `var klaroConfig = ${JSON.stringify(
      klaroConfig
    )};`;
    document.head.appendChild(klaroConfigScript);
  }

  ngAfterViewInit(): void {
    // Load Klaro.js
    const klaroScript = document.createElement('script');
    klaroScript.src = 'assets/klaro.js'; // Adjust the path as needed
    klaroScript.async = true;
    document.head.appendChild(klaroScript);

    klaroScript.onload = () => {
      // Klaro.js is now loaded
      this.initializeKlaro();
    };
  }

  initializeKlaro(): void {
    // Access Klaro.js properties or methods
    window.klaro.setup();
    const klaroManager = window.klaro.getManager();
    console.log('loaded klaro');
    this._hasConsent = klaroManager.getConsent('soundcloud');
    // Now you can interact with Klaro.js as needed
  }

  getIframeUrl(song: Song | null = null): SafeUrl | null {
    if (!song) {
      song = this.song;
    }
    if (song?.soundcloud?.iframe)
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        song?.soundcloud?.iframe
      );
    return null;
  }
}
