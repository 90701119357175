import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ExternalSource, Song, SongCover } from './song';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SongService {
  constructor(private http: HttpClient) {}

  getAll(includeCover: boolean = true): Observable<Song[]> {
    return this.http
      .get<Song[]>(
        `${environment.apiServer}/songs?sort=Title${
          includeCover ? '&populate=Cover' : ''
        }`,
        {
          headers: this.getHeaders(),
        }
      )
      .pipe(
        map<any, Song[]>((res) => this.getSongsFromResponse(res, true)),
        catchError(this.errorHandler)
      );
  }

  getSongById(
    id: string,
    includeCover: boolean = true
  ): Observable<Song | null> {
    return this.http
      .get<Song>(
        `${environment.apiServer}/songs/${id}${
          includeCover ? '?populate=Cover' : ''
        }`,
        {
          headers: this.getHeaders(),
        }
      )
      .pipe(
        map<any, Song | null>((res) => this.mapEntryToSong(res.data, false)),
        catchError(this.errorHandler)
      );
  }

  private getSongsFromResponse(res: any, useThumbnail: boolean): Song[] {
    if (res['data'] && res['data'].length > 0) {
      return res['data']
        .map((entry: any) => this.mapEntryToSong(entry, useThumbnail))
        .filter((song: Song | null) => song !== null);
    }
    return [];
  }
  private mapEntryToSong(entry: any, useThumbnail: boolean): Song | null {
    if (!entry || typeof entry.attributes === 'undefined') return null;
    const attr = entry.attributes;
    let song = new Song(
      entry.id,
      attr.Title,
      attr.locale,
      attr.createdAt,
      attr.publishedAt,
      attr.Lyrics,
      attr.updatedAt,
      null,
      null,
      this.getSongCoverFromAttribute(attr, useThumbnail)
    );
    if (attr.Youtube) {
      song.youtube = this.getExternalSource(attr.Youtube);
    }
    if (attr.Soundcloud) {
      song.soundcloud = this.getExternalSource(attr.Soundcloud);
    }
    return song;
  }

  private getSongCoverFromAttribute(
    attr: any,
    useThumbnail: boolean
  ): SongCover | null {
    if (attr.Cover && attr.Cover.data) {
      if (useThumbnail) {
        new SongCover(
          attr.Cover.data.attributes.formats.thumbnail.url,
          attr.Cover.data.attributes.formats.thumbnail.width,
          attr.Cover.data.attributes.formats.thumbnail.height
        );
      }

      return new SongCover(
        attr.Cover.data.attributes.url,
        attr.Cover.data.attributes.width,
        attr.Cover.data.attributes.height
      );
    }
    return null;
  }

  private getExternalSource(externalSourceString: string): ExternalSource {
    const info = JSON.parse(externalSourceString);
    let div = document.createElement('div');
    div.innerHTML = info?.rawData?.html;
    const iframe = div.getElementsByTagName('iframe')[0];
    return new ExternalSource(info?.url, iframe?.src);
  }

  private errorHandler(error: Error | any): Observable<any> {
    console.log(error);
    return of(null);
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${environment.apiKey}`,
    });
  }
}
